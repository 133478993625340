.react-datepicker__input-container input {
  display: block;
  height: 50px;
  width: "100%" !important;
  padding-left: "20px" !important;
}

.datePicker {
  background-color: "black" !important;
}

.radio {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.radio label {
  margin-left: 20px;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: rgba(255, 255, 255, 0.7);
}
