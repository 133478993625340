.MuiMenu-paper {
  margin-top: 54px !important;
}
.MuiSelect-select.MuiSelect-select {
  display: flex;
  align-items: center;
  line-height: 1.5;
}
.custom-arrow-down {
  margin-right: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  cursor: pointer;
  z-index: 10;
  user-select: none;
  background-color: transparent;
  border: 0;
}
.MuiListItem-button .custom-arrow-down {
  display: none;
}
