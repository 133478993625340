.c-user-action-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.c-user-search {
  width: 100%;
  margin-bottom: 20px;
}
.c-user-csv-svg {
  margin-right: 8px;
}
.c-user-icon {
  margin-right: 10px;
}
.MuiButtonBase-root.create-user {
  margin-right: 9px;
}
.user-list-table .MuiDataGrid-row {
  cursor: pointer;
}
@media screen and (min-width: 820px) {
  .c-user-search {
    width: 70%;
    margin-bottom: 0;
  }
  .c-user-action-container {
    flex-direction: row;
  }
}
