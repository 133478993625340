.select {
  width: 84px;
  height: 30px;
}
.select .MuiSelect-select.MuiSelect-select,
.select .MuiSelect-outlined.MuiSelect-outlined {
  font-size: 12px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  background-color: #1f2327;
  border-radius: 8px;
  border-color: #1f2327;
  outline: none;
}
.select .MuiSelect-icon {
  display: flex;
  align-items: center;
  top: 0;
}
