.approved,
.not-approved {
  width: 88px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #ffffff;
}
.approved {
  background-color: #027a48;
}
.not-approved {
  background-color: #b42318;
}

.csv-button-container {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
}

.movie-list-vista {
  margin-left: 10px !important;
}
.movie-list-seacrh-container {
  width: 100%;
}
.movie-list-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}
.movie-list-btn-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}
@media screen and (min-width: 1024px) {
  .movie-list-seacrh-container {
    width: 70%;
  }
  .movie-list-container {
    flex-direction: row;
    align-items: center;
  }
  .movie-list-btn-container {
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-end;
  }
  .movie-list-vista {
    margin: 0;
  }
}

.movie-list-table .MuiDataGrid-row {
  cursor: pointer;
}
