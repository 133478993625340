@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Roboto:wght@400;500;700&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html,
body {
  width: 100%;
  height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* scrollbar-color: #e3e3e3 transparent; */
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}
::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: #bebfc0;
}
::-webkit-scrollbar-thumb:active {
  background: #e3e3e3;
}
::-webkit-scrollbar-track {
  background: rgb(93, 92, 92);
  border-radius: 53px;
}
::-webkit-scrollbar-track:hover {
  background: rgb(93, 92, 92);
}
::-webkit-scrollbar-track:active {
  background: #796d65;
}
::-webkit-scrollbar-corner {
  background: rgb(93, 92, 92);
}
/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px #16161a inset !important;
  -webkit-box-shadow: 0 0 0px 1000px #16161a inset !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
  background-image: none !important;
  color: #fff !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}
button {
  background: none;
  border: 0;
  outline: none;
  cursor: pointer;
}
a {
  text-decoration: none;
  color: inherit;
}
.active {
  color: #a233e2;
}
.active-user-dropdown {
  color: #fff;
}
.inactive-user-dropdown {
  color: #666679;
}
.error-txt {
  color: red;
}

.btn-clearToken {
  background: #fff;
  color: crimson;
}

.btn-delete {
  background: crimson;
  color: #fff;
}

.btn-edit {
  background: #fff;
  color: crimson;
}

.btn-file {
  position: relative;
  overflow: hidden;
  color: #fff;
  /* font-weight: 600; */
  /* background: #272729;
  border-radius: 5.1px;
  padding: 10px 30px;
  height: 45.9px; */
  outline: none;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  /* font-size: 17.85px; */
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  text-decoration-line: underline;

  /* Purple 500 */

  color: #a233e2;
}
.btn-file input[type="file"].custom-file-input {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  /* font-size: 100px; */
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

/* Uploader */

.inline-uploader {
  /* border: 1px solid #fff; */
  width: 100%;
  height: 60px;
  padding: 14px 15px;
  display: flex;
  align-items: center;
  background: #242426;
  border-radius: 4px 4px 0 0;
}

.inline-uploader .side-text {
  color: rgba(255, 255, 255, 0.6);
  margin-left: 17px;
  font-size: 13px;
  letter-spacing: 0.12px;
  line-height: 24px;
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow-x: hidden;
}

.inline-uploader .btn-file {
  position: relative;
  overflow: hidden;
  color: #a233e2;
  background: transparent;
  font-weight: 600;
  line-height: 19px;
  border: 1px solid #4b4552;
  border-radius: 4px;
  padding: 4px 8px 15px;
  height: 32px;
  outline: none;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
}
.inline-uploader .btn-file input[type="file"].custom-file-input {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

ul.chart-ul {
  list-style-type: none;
}

ul.chart-ul li:before {
  content: "•";
  /* font-size: 37px; or whatever */
  padding-right: 5px;
  margin-bottom: -10px;
}

ul.chart-ul li {
  font-size: 14px; /* or whatever */
  /* padding-bottom: -15px; */
}

/* Pagination */

.current-page {
  font-size: 1.5rem;
  vertical-align: middle;
}

.pagination-container__pane {
  display: flex !important;
  padding: 20px 0px;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
}

ul.pagination {
  display: flex;
  margin-left: -40px;
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
ul.pagination li.page-item.active a.page-link {
  color: #fff !important;
  background-color: #303030 !important;
  border-color: #303030 !important;
}
ul.pagination a.page-link {
  padding: 0.75rem 1rem;
  min-width: 3.5rem;
  text-align: center;
  font-size: 9px;
  box-shadow: none !important;
  border-color: #1e1e21 !important;
  color: #f2f2f2;
  font-weight: 900;
  background-color: #1e1e21 !important;
}
ul.pagination a.page-link:hover {
  background-color: #f4f4f4;
}
.MuiDataGrid-row.Mui-odd {
  background-color: #16161a;
}
.MuiDialogActions-root {
  padding: 8px 24px;
}
.MuiDataGrid-root .MuiDataGrid-columnHeader--moving {
  background-color: inherit !important;
}
